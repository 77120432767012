exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-producten-boxen-tsx": () => import("./../../../src/pages/producten/boxen.tsx" /* webpackChunkName: "component---src-pages-producten-boxen-tsx" */),
  "component---src-pages-producten-eetboxen-tsx": () => import("./../../../src/pages/producten/eetboxen.tsx" /* webpackChunkName: "component---src-pages-producten-eetboxen-tsx" */),
  "component---src-pages-producten-index-tsx": () => import("./../../../src/pages/producten/index.tsx" /* webpackChunkName: "component---src-pages-producten-index-tsx" */),
  "component---src-pages-producten-slaapfeestjes-tsx": () => import("./../../../src/pages/producten/slaapfeestjes.tsx" /* webpackChunkName: "component---src-pages-producten-slaapfeestjes-tsx" */),
  "component---src-pages-producten-waardebon-tsx": () => import("./../../../src/pages/producten/waardebon.tsx" /* webpackChunkName: "component---src-pages-producten-waardebon-tsx" */)
}

